<template>
	<div class="box">
		<top-nav backImage bgColor="#fff" @back="back"><span class="title">健康告知</span></top-nav>
		<div class="top">
			<div class="top_left">重要</div>
			<p class="top_right">请确认被保险人的健康状况是否存在以下问题，请如 实告知，否则将影响理赔，且本公司有权解除保险合同。</p>
		</div>
		<div :class="['bg_fff', { mt_2: index }]" v-for="(item, index) in productIntroduce" :key="index">
			<div class="detail_title">{{ item.title }}</div>
			<div class="service_content">
				<p v-html="item.content"></p>
			</div>
		</div>
		<!-- 按钮 -->
		<div class="btn_box">
			<div class="btn" @click="handleSubmit(true)">部分为是</div>
			<van-button :loading="loading" class="btn bg_main" @click="handleSubmit(false)">全部为否</van-button>
		</div>
	</div>
</template>

<script>
import { submitBill, http_getInsPayUrl } from '@/request/api';
import { Button, Toast } from 'vant';

export default {
	components: {
		[Button.name]: Button,
		[Toast.name]: Toast,
	},
	data() {
		return {
			productIntroduce: [],
			loading: false,
			timer: null, //定时器
		};
	},
	created() {
		this.productIntroduce = this.$route.params.data;
	},
	beforeDestroy() {
		clearInterval(this.timer);
	},
	methods: {
		back() {
			this.$router.go(-1);
		},
		handleSubmit(val) {
			if (val) {
				this.$router.replace({ name: 'health_no_inform' });
			} else {
				this.loading = true;
				submitBill(this.$route.params.orderData)
					.then(res => {
						khs.finish(res.data.id);
						// 获取支付链接
						this.getPayStatus(res.data.id).then(() => {
							this.$store.commit('set_billId', res.data.id);
							this.$router.replace({
								name: 'health_order_pay',
								params: {
									isRedict: false,
								},
							});
						});
					})
					.catch(() => {
						this.$router.go(-1);
					})
					.finally(() => {
						this.loading = false;
					});
			}
		},
		// 轮训调用支付链接
		getPayStatus(id) {
			return new Promise((resolve, reject) => {
				Toast.loading({
					duration: 0,
					forbidClick: true,
					message: '正在审核中....',
				});
				this.timer = setInterval(async () => {
					const res = await http_getInsPayUrl(id);
					if (res.message === '200') {
						Toast.clear();
						clearInterval(this.timer);
						resolve(res.data);
					} else if (res.message === '400' || res.message === '201') {
						Toast.clear();
						this.$router.go(-1);
						clearInterval(this.timer);
						Toast(res.data);
						reject();
					}
				}, 3000);
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.title {
	color: #000 !important;
}
.box {
	background-color: $b_border_color;
	padding-bottom: 1rem;
}
.bg_fff {
	background-color: #fff;
}
.mt_2 {
	margin-top: 0.2rem;
}
.detail_title {
	font-size: 0.28rem;
	font-weight: 700;
	color: rgba(0, 0, 0, 0.8);
	padding: 0.2rem 0.4rem;
	border-bottom: 0.02rem solid #e8eaec;
}
.top {
	width: 100%;
	height: 1.4rem;
	background: linear-gradient(180deg, #fff4f2 0%, #fedfd3 100%);
	padding: 0 0.4rem;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 0.28rem;
	box-sizing: border-box;
	.top_left {
		width: 0.4rem;
		height: 0.76rem;
		background: linear-gradient(180deg, #fe8b2e 0%, #ff6f32 100%);
		border-radius: 0.04rem 0.04rem 0.04rem 0.04rem;
		color: #fff;
		font-weight: 500;
		text-align: center;
		margin-right: 0.32rem;
		padding: 0.1rem 0.08rem;
	}
	.top_right {
		font-weight: 700;
		color: rgba(0, 0, 0, 0.8);
		font-size: 0.24rem;
	}
}
.service_content {
	padding: 0.2rem 0.4rem;
	color: rgba(0, 0, 0, 0.8);
	font-size: 0.24rem;
}
.btn_box {
	width: 100%;
	height: 0.98rem;
	display: flex;
	justify-content: space-between;
	position: fixed;
	bottom: 0;
	left: 0;
	font-size: 0.36rem;
	background: #e8eaec;
	.btn {
		text-align: center;
		line-height: 0.98rem;
		flex: 1;
	}
	.bg_main {
		background: $color_main;
		color: #fff;
		height: 100%;
		border: 0;
	}
}
</style>
